<template>
  <footer class="footer" id="contacts">
    <div class="footer-container">
      <div class="footer-content">
        <div class="footer-main-menu-content">
          <div class="footer-menu-wrapper footer-menu-wrapper--contacts">
            <div class="footer-menu-title">Контакты</div>
            <div class="footer-menu-content">
              <ul class="footer-nav">
                <li class="footer-nav-item">
                  <span>Полунин Вячеслав</span>
                  <span>+7(921)580-40-82</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer-menu-wrapper footer-menu-wrapper--address">
            <div class="footer-menu-title">Адрес</div>
            <div class="footer-menu-content">
              <ul class="footer-nav">
                <li class="footer-nav-item">
                  192029, г. Санкт-Петербург, пр-кт Обуховской Обороны, д. 70 к.
                  2 литера А, помещ. 1-н ком. 383
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-socials-content">
          <div class="footer-menu-title">Социальные сети</div>
          <div class="social-media-wrapper">
            <a
              href="https://vk.com/fem102lab"
              target="_blank"
              class="social-media-link"
              ><i class="fa-brands fa-vk"></i></a
            ><a
              href="https://t.me/fem102lab"
              target="_blank"
              class="social-media-link icon-size-13px"
              ><i class="fa-brands fa-telegram"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="footer-content-bottom">
        <a href="/">
          <div class="logo">
            <span>102</span>
            <div class="logo-circle">lab</div>
          </div>
        </a>
        <div class="footer-company-rights">
          ОГРН 237800123220 от 26 октября 2023 г.<br />
          ИНН 7811792720<br />
          КПП 781101001
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  border-top: 1px solid #302c3f;
  padding-top: 11rem;
  padding-bottom: 3.4rem;
}

.footer-container {
  max-width: 120rem;
  margin: 0 auto;
}

.footer-content {
  margin-bottom: 11rem;
  display: flex;
  gap: 2.8rem;
}

.footer-main-menu-content {
  display: flex;
  flex: 2;
  gap: 2.8rem;
}

.footer-menu-wrapper--contacts {
  flex: 1.5;
}

.footer-menu-wrapper--address {
  flex: 2.5;
}

.footer-menu-title {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1em;
}

.footer-nav-item {
  font-size: 1.8rem;
  color: #b7b4c7;
  line-height: 1.111em;
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}

span {
  display: inline-block;
}

.footer-socials-paragraph {
  margin-bottom: 2.8rem;
  line-height: 1.667em;
  font-size: 1.8rem;
  color: #b7b4c7;
}

.social-media-wrapper {
  display: flex;
  gap: 1.5rem;
}

.social-media-link {
  width: 29px;
  max-height: 29px;
  min-height: 29px;
  min-width: 29px;
  background-image: linear-gradient(45deg, #00aad5, #9a85bb 46%, #ea515f 91%);
  color: #fff;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  display: flex;
}

.footer-content-bottom {
  border-top: 1px solid #302c3f;
  /* margin-bottom: -1.5rem; */
  padding-top: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 11rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 2.2rem;
  cursor: pointer;
  min-width: 12rem;
}

.logo > span {
  transition: all 0.3s;
  display: block;
}

.logo:hover {
  font-size: 2.4rem;

  .logo-circle {
    min-width: 6.4rem;
    min-height: 6.4rem;
  }
}

.logo-circle {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 6rem;
  min-height: 6rem;
  border: 2px solid transparent;
  border-radius: 80px;
  background-image: linear-gradient(var(--bg-color), var(--bg-color)),
    linear-gradient(45deg, #00aad5, #9a85bb 50%, #ea515f);
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition: all 0.3s;
}

.footer-company-rights {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  line-height: 1.667em;
  color: #b7b4c7;
}

a {
  color: #fff;
}

@media (max-width: 1250px) {
  .footer {
    padding-top: 5rem;
  }
  .footer-container {
    max-width: 90rem;
  }
  .footer-content {
    margin-bottom: 5rem;
    display: flex;
    gap: 2rem;
  }
  .footer-main-menu-content {
    gap: 2rem;
  }
  .footer-menu-wrapper--contacts {
    flex: 1.5;
  }
  .footer-menu-wrapper--address {
    flex: 2.5;
  }
  .footer-menu-title {
    font-size: 1.8rem;
  }
  .footer-nav-item {
    font-size: 1.4rem;
  }
  .social-media-wrapper {
    gap: 1.2rem;
  }
  .social-media-link {
    width: 29px;
    max-height: 29px;
    min-height: 29px;
    min-width: 29px;
    font-size: 1.6rem;
  }
  .footer-content-bottom {
    padding-top: 2.4rem;
    min-height: 8rem;
  }
  .logo {
    gap: 0.8rem;
    font-size: 1.6rem;
    cursor: pointer;
    min-width: 12rem;
  }
  .logo > span {
    transition: all 0.3s;
    display: block;
  }
  .logo:hover {
    font-size: 1.6rem;

    .logo-circle {
      min-width: 4.8rem;
      min-height: 4.8rem;
    }
  }
  .logo-circle {
    min-width: 4.8rem;
    min-height: 4.8rem;
    border: 2px solid transparent;
  }
  .footer-company-rights {
    margin-bottom: 0rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 925px) {
  .footer {
    padding-top: 4rem;
  }
  .footer-container {
    max-width: 60rem;
  }
  .footer-content {
    margin-bottom: 4rem;
    display: flex;
    gap: 2rem;
  }
  .footer-main-menu-content {
    gap: 2rem;
  }
  .footer-menu-wrapper--contacts {
    flex: 1.5;
  }
  .footer-menu-wrapper--address {
    flex: 2.5;
  }
  .footer-menu-title {
    font-size: 1.6rem;
  }
  .footer-nav-item {
    font-size: 1.2rem;
    flex-direction: column;
  }
  .social-media-wrapper {
    gap: 1.2rem;
  }
  .social-media-link {
    width: 29px;
    max-height: 29px;
    min-height: 29px;
    min-width: 29px;
    font-size: 1.6rem;
  }
  .footer-content-bottom {
    padding-top: 2.4rem;
    min-height: 8rem;
  }
  .logo {
    gap: 0.6rem;
    font-size: 1.4rem;
    cursor: pointer;
    min-width: 10rem;
  }
  .logo:hover {
    font-size: 1.4rem;

    .logo-circle {
      min-width: 4.4rem;
      min-height: 4.4rem;
    }
  }
  .logo-circle {
    min-width: 4.4rem;
    min-height: 4.4rem;
  }
  .footer-company-rights {
    margin-bottom: 0rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 615px) {
  .footer {
    padding-top: 4rem;
  }
  .footer-container {
    max-width: 40rem;
  }
  .footer-content {
    margin-bottom: 4rem;
    display: flex;
    gap: 2rem;
  }
  .footer-main-menu-content {
    gap: 1.4rem;
  }
  .footer-menu-wrapper--contacts {
    flex: 2;
  }
  .footer-menu-wrapper--address {
    flex: 2.8;
  }
  .footer-menu-title {
    font-size: 1.6rem;
  }
  .footer-nav-item {
    font-size: 1.2rem;
    flex-direction: column;
    line-height: 1.6;
    gap: 0rem;
  }
  .footer-main-menu-content {
    flex: 3;
  }
  .footer-socials-content {
    flex: 1;
  }
  .social-media-wrapper {
    gap: 1.2rem;
  }
  .social-media-link {
    width: 29px;
    max-height: 29px;
    min-height: 29px;
    min-width: 29px;
    font-size: 1.6rem;
  }
  .footer-content-bottom {
    padding-top: 2.4rem;
    min-height: 8rem;
  }
  .logo {
    gap: 0.6rem;
    font-size: 1.4rem;
    cursor: pointer;
    min-width: 10rem;
  }
  .logo:hover {
    font-size: 1.4rem;

    .logo-circle {
      min-width: 4.4rem;
      min-height: 4.4rem;
    }
  }
  .logo-circle {
    min-width: 4.4rem;
    min-height: 4.4rem;
  }
  .footer-company-rights {
    margin-bottom: 0rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 415px) {
  .footer {
    padding-top: 4rem;
  }
  .footer-container {
    max-width: 30rem;
  }
  .footer-content {
    margin-bottom: 4rem;
    display: flex;
    gap: 2rem;
  }
  .footer-main-menu-content {
    gap: 1.4rem;
    flex-direction: column;
  }
  .footer-menu-wrapper--contacts {
    flex: 2;
  }
  .footer-menu-wrapper--address {
    flex: 2.8;
  }
  .footer-menu-title {
    font-size: 1.4rem;
  }
  .footer-nav-item {
    font-size: 1rem;
    flex-direction: column;
    line-height: 1.6;
    gap: 0rem;
  }
  .footer-main-menu-content {
    flex: 3;
  }
  .footer-socials-content {
    flex: 1;
  }
  .social-media-wrapper {
    gap: 1.2rem;
  }
  .social-media-link {
    width: 25px;
    max-height: 25px;
    min-height: 25px;
    min-width: 25px;
    font-size: 1.4rem;
  }
  .footer-content-bottom {
    padding-top: 2.4rem;
    min-height: 8rem;
  }
  .footer-content-bottom > a {
    flex: 2;
  }
  .logo {
    gap: 0.6rem;
    font-size: 1.4rem;
    cursor: pointer;
    min-width: 10rem;
  }
  .logo:hover {
    font-size: 1.4rem;

    .logo-circle {
      min-width: 4.4rem;
      min-height: 4.4rem;
    }
  }
  .logo-circle {
    min-width: 4.4rem;
    min-height: 4.4rem;
  }
  .footer-company-rights {
    margin-bottom: 0rem;
    font-size: 1rem;
    flex: 2;
  }
}
</style>
