<template>
  <section class="section-about" id="about">
    <div class="about">
      <div class="about-header">
        <h2>Примеры цифровых проектов и приложений</h2>
        <!-- <div class="btn-gradient"></div> -->
      </div>
      <div class="about-content">
        <div class="cards-container">
          <div class="card">
            <div class="card-content">
              <div class="card-title-num">1</div>
              <h3 class="card-title">ТерраГео</h3>
              <p class="card-paragraph">
                Система автоматизированного мониторинга за состоянием
                вечномерзлых грунтов
              </p>
            </div>
            <div class="img-wrapper">
              <img src="../../assets/imgs/about/terrageo.jpg" alt="picture" />
            </div>
          </div>
          <div class="card">
            <div class="card-content">
              <div class="card-title-num">2</div>
              <h3 class="card-title">102lab-fem</h3>
              <p class="card-paragraph">
                Расчетный модуль на основе численного метода конечных элементов,
                который позволяет решать основные геотехнические задачи
              </p>
            </div>
            <div class="img-wrapper">
              <img src="../../assets/imgs/about/102lab-fem.jpg" alt="picture" />
            </div>
          </div>
          <div class="card">
            <div class="card-content">
              <div class="card-title-num">3</div>
              <h3 class="card-title">102лаб</h3>
              <p class="card-paragraph">
                Система автоматизированного геотехнического мониторинга за
                состоянием зданий и сооружения
              </p>
            </div>
            <div class="img-wrapper">
              <img src="../../assets/imgs/about/102lab.jpg" alt="picture" />
            </div>
          </div>
        </div>
        <div class="bg-gradient-blur-wrapper">
          <div class="bg-gradient-blur-circle-1"></div>
          <div class="bg-gradient-blur-circle-2"></div>
          <div class="bg-gradient-blur-circle-3"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.section-about {
  padding-bottom: 20rem;
}

.about {
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
}

.about-header {
  max-width: 42rem;
  margin-right: 2.6rem;
  position: sticky;
  top: 20px;
  /* font-family: 'Thiccc boi bold'; */
  font-size: 4.4rem;
  line-height: 1.182em;
}

.about-header h2 {
  font-weight: 700;
}

.about-content {
  max-width: 74.4rem;
  min-width: 60rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 4rem;
  position: relative;
}

.card {
  box-shadow: 0 10px 34px 0 rgba(0, 0, 0, 0.35);
  border: 2px solid rgba(255, 255, 255, 0.06);
  display: flex;
  background-color: #1d1a27;
  border-radius: 22px;
  overflow: hidden;
}

.card-content {
  max-width: 42.1rem;
  flex: 1;
  padding: 4.8rem;
}

.card-title-num {
  background-color: #5e5b71;
  border-radius: 12px;
  margin-bottom: 2.6rem;
  padding: 1rem 2.1rem;
  display: inline-block;
  font-size: 2.4rem;
  font-weight: 700;
  /* font-family: 'Thiccc boi bold'; */
  line-height: 1.5em;
}

.card-title {
  margin-bottom: 0.8rem;
  font-size: 2.4rem;
  font-weight: 700;
  /* font-family: 'Thiccc boi bold'; */
  line-height: 1.5em;
}

.card-paragraph {
  color: #c9c9c9;
  line-height: 1.667em;
  font-size: 1.8rem;
}

.img-wrapper {
  width: 100%;
  max-width: 55%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.bg-gradient-blur-wrapper {
  max-width: 40%;
  max-height: 40%;
}

.bg-gradient-blur-circle-1 {
  width: 70%;
  max-height: 70%;
  min-height: 70%;
  min-width: 70%;
  border-radius: 1000000px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ea515f;
}

.bg-gradient-blur-circle-2 {
  width: 70%;
  max-height: 70%;
  min-height: 70%;
  min-width: 70%;
  border-radius: 1000000px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #9a85bb;
}

.bg-gradient-blur-circle-3 {
  width: 70%;
  max-height: 70%;
  min-height: 70%;
  min-width: 70%;
  border-radius: 1000000px;
  position: absolute;
  background-color: #ea515f;
}

@media (max-width: 1250px) {
  .about {
    max-width: 90rem;
  }
  .about-header {
    max-width: 42rem;
    font-size: 3.6rem;
  }
  .about-content {
    max-width: 74.4rem;
    min-width: 60rem;
  }
  .cards-container {
    row-gap: 3rem;
  }
  .card-content {
    max-width: 40rem;
    padding: 4.8rem;
  }
  .card-title-num {
    margin-bottom: 2rem;
    padding: 1.2rem 1.9rem;
    font-size: 1.6rem;
  }
  .card-title {
    margin-bottom: 0.8rem;
    font-size: 1.8rem;
  }
  .card-paragraph {
    font-size: 1.4rem;
  }
  .img-wrapper {
    width: 100%;
    max-width: 40%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

@media (max-width: 925px) {
  .section-about {
    padding-bottom: 8rem;
  }
  .about {
    max-width: 60rem;
  }
  .about-header {
    max-width: 15rem;
    font-size: 2rem;
    margin-right: 0.2rem;
  }
  .about-content {
    min-width: 24rem;
  }
  .cards-container {
    row-gap: 2rem;
  }
  .card-content {
    min-width: 10rem;
    padding: 2rem;
    padding-right: 0.8rem;
  }
  .card-title-num {
    margin-bottom: 1.6rem;
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
  }
  .card-title {
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
  }
  .card-paragraph {
    font-size: 1.2rem;
  }
  .img-wrapper {
    width: 100%;
    max-width: 40%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

@media (max-width: 615px) {
  .section-about {
    padding-bottom: 8rem;
  }
  .about {
    max-width: 40rem;
    flex-direction: column;
  }
  .about-header {
    max-width: 40rem;
    font-size: 2.1rem;
    margin-right: 0.2rem;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2.4rem;
    position: relative;
    top: 0;
  }
  .cards-container {
    row-gap: 2rem;
  }
  .card-content {
    padding: 2rem;
    padding-right: 0.8rem;
  }
  .card-title-num {
    margin-bottom: 1.6rem;
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
  }
  .card-title {
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
  }
  .card-paragraph {
    font-size: 1.2rem;
  }
  .img-wrapper {
    width: 100%;
    max-width: 40%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

@media (max-width: 415px) {
  .section-about {
    padding-bottom: 8rem;
  }
  .about {
    max-width: 30rem;
    flex-direction: column;
  }
  .about-header {
    max-width: 30rem;
    font-size: 1.8rem;
    margin-right: 0.2rem;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2.4rem;
    position: relative;
    top: 0;
  }
  .cards-container {
    row-gap: 2rem;
  }
  .card-content {
    padding: 1.4rem 2rem;
    padding-right: 0.8rem;
  }
  .card-title-num {
    margin-bottom: 1rem;
    padding: 0.6rem 1.4rem;
    font-size: 1.4rem;
  }
  .card-title {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
  }
  .card-paragraph {
    font-size: 1rem;
  }
  .img-wrapper {
    width: 100%;
    max-width: 40%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>
