<template>
  <section>
    <div class="hero">
      <h1>
        Активно применяем IT-технологии в
        <br />
        <span class="gradient-text">Инженерии</span>
      </h1>
      <p>
        Построенные на личном финансировании и собственном энтузиазме реальные
        проекты и веб-приложения
      </p>
      <div class="btn-container">
        <div class="btn-gradient">
          <a id="discover" href="#introduce">Узнайте больше</a>
        </div>
      </div>
    </div>
    <div class="hero-info">
      <div class="slider">
        <div class="slide">
          <img
            class="hero-img"
            src="../../assets/imgs/content/пример_модели_1.png"
            alt="Пример модели"
          />
        </div>

        <div class="slide">
          <img
            class="hero-img"
            src="../../assets/imgs/content/пример_модели_2.png"
            alt="Пример модели"
          />
        </div>

        <div class="slide">
          <img
            class="hero-img"
            src="../../assets/imgs/content/пример_модели_3.png"
            alt="Пример модели"
          />
        </div>

        <button @click="prevSlide" class="slider__btn slider__btn--left">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
        <button @click="nextSlide" class="slider__btn slider__btn--right">
          <i class="fa-solid fa-arrow-right"></i>
        </button>
        <div class="dots"></div>
      </div>
      <div class="bg-gradient-blur-wrapper bg-gradient-blur-wrapper--1">
        <div class="bg-gradient-blur-circle bg-gradient-blur-circle--1"></div>
        <div class="bg-gradient-blur-circle bg-gradient-blur-circle--2"></div>
        <div class="bg-gradient-blur-circle bg-gradient-blur-circle--3"></div>
      </div>
      <div class="bg-gradient-blur-wrapper bg-gradient-blur-wrapper--2">
        <div class="bg-gradient-blur-circle bg-gradient-blur-circle--4"></div>
        <div class="bg-gradient-blur-circle bg-gradient-blur-circle--5"></div>
        <div class="bg-gradient-blur-circle bg-gradient-blur-circle--6"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      curSlide: 0,
      slides: null,
      dotContainer: null,
      maxSlide: 0,
    };
  },
  computed: {
    // compSlides() {
    //   return document.querySelectorAll('.slide');
    // },
    // compMaxSlide() {
    //   return this.slides.length;
    // },
  },
  methods: {
    createDots() {
      const dotContainer = document.querySelector('.dots');

      this.slides.forEach(function (_, i) {
        dotContainer.insertAdjacentHTML(
          'beforeend',
          `<button class="dots__dot" data-slide="${i}"></button>`
        );
      });
    },
    activateDot(slide) {
      document
        .querySelectorAll('.dots__dot')
        .forEach((dot) => dot.classList.remove('dots__dot--active'));

      document
        .querySelector(`.dots__dot[data-slide="${slide}"]`)
        .classList.add('dots__dot--active');
    },
    goToSlide(slide) {
      this.slides.forEach(
        (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
      );
    },
    nextSlide() {
      if (this.curSlide === this.maxSlide - 1) {
        this.curSlide = 0;
      } else {
        this.curSlide++;
      }

      this.goToSlide(this.curSlide);
      this.activateDot(this.curSlide, this.slides);
    },
    prevSlide() {
      if (this.curSlide === 0) {
        this.curSlide = this.maxSlide - 1;
      } else {
        this.curSlide--;
      }

      this.goToSlide(this.curSlide);
      this.activateDot(this.curSlide);
    },
  },
  mounted() {
    document.querySelector('#discover').addEventListener('click', (e) => {
      e.preventDefault();

      const id = e.target.getAttribute('href');
      document.querySelector(id).scrollIntoView({ behavior: 'smooth' });
    });

    this.slides = document.querySelectorAll('.slide');
    this.dotContainer = document.querySelector('.dots');
    this.maxSlide = this.slides.length;

    this.goToSlide(0);
    this.createDots();
    this.activateDot(0);

    const that = this;

    this.dotContainer.addEventListener('click', function (e) {
      if (e.target.classList.contains('dots__dot')) {
        const { slide } = e.target.dataset;

        that.goToSlide(slide);
        that.activateDot(slide);
      }
    });
  },
};
</script>

<style scoped>
section {
  text-align: center;
}

.hero {
  max-width: 90rem;
  margin: 6rem auto;
  margin-bottom: 7.8rem;
}

h1 {
  font-size: 7.8rem;
  line-height: 1.077em;
  margin-bottom: 2.4rem;
  font-weight: 700;
  /* font-family: 'Thiccc boi bold'; */
  letter-spacing: 1.4px;
}

p {
  max-width: 79.6rem;
  color: var(--text-color-g);
  margin: 0 auto;
  font-size: 2.4rem;
  line-height: 1.583em;
  margin-bottom: 5rem;
}

.hero-info {
  max-width: 125rem;
  margin: 0 auto;
  position: relative;
}

.hero-img {
  max-width: 100%;
  max-height: 100%;
  z-index: 100;
}

.hero-img-terrageo {
  max-width: 19%;
  position: absolute;
  top: 66px;
  left: 164px;
}

.hero-img-geotech {
  max-width: 19%;
  position: absolute;
  bottom: 87px;
  right: 100px;
}

.bg-gradient-blur-wrapper--1 {
  top: -230px;
  left: -209px;
}

.bg-gradient-blur-wrapper--2 {
  bottom: 25px;
  right: -212px;
}

.bg-gradient-blur-circle {
  min-width: 70%;
  max-width: 70%;
  max-height: 70%;
  min-height: 70%;
  position: absolute;
  border-radius: 1000000px;
}

.bg-gradient-blur-circle--1 {
  background-color: #00aad5;
  top: 0;
  right: 0;
}

.bg-gradient-blur-circle--2 {
  background-color: #00aad5;
  bottom: 0;
  right: 0;
}

.bg-gradient-blur-circle--3 {
  background-color: #ea515f;
}

.bg-gradient-blur-circle--4 {
  background-color: #00aad5;
  top: 0;
  right: 0;
}

.bg-gradient-blur-circle--5 {
  background-color: #9a85bb;
  bottom: 0;
  right: 0;
}

.bg-gradient-blur-circle--6 {
  background-color: #ea515f;
}

@media (max-width: 1250px) {
  h1 {
    font-size: 5.6rem;
  }

  p {
    font-size: 1.8rem;
  }

  #discover {
    font-size: 1.4rem;
  }

  .hero {
    max-width: 60rem;
    margin: 4rem auto;
    margin-bottom: 0;
  }

  .hero-info {
    max-width: 90rem;
  }

  .hero-img {
    max-width: 80%;
    max-height: 80%;
    z-index: 100;
  }
}

@media (max-width: 925px) {
  h1 {
    font-size: 3.8rem;
  }

  p {
    font-size: 1.6rem;
  }

  #discover {
    font-size: 1.2rem;
  }

  .hero {
    max-width: 40rem;
    margin: 4rem auto;
    margin-bottom: 0;
  }

  .hero-info {
    max-width: 40rem;
  }

  .hero-img {
    max-width: 60%;
    max-height: 60%;
    z-index: 100;
  }
}

@media (max-width: 615px) {
  h1 {
    font-size: 3rem;
  }

  p {
    font-size: 1.2rem;
    max-width: 33rem;
  }

  #discover {
    font-size: 1rem;
  }

  .hero {
    max-width: 35rem;
    margin: 4rem auto;
    margin-bottom: 0;
  }

  .hero-info {
    max-width: 35rem;
  }

  .hero-img {
    max-width: 55%;
    max-height: 55%;
  }
}

@media (max-width: 415px) {
  h1 {
    font-size: 2.4rem;
  }

  p {
    font-size: 1rem;
    max-width: 28rem;
  }

  #discover {
    font-size: 0.9rem;
  }

  .hero {
    max-width: 30rem;
    margin: 2rem auto;
    margin-bottom: 0;
  }

  .hero-info {
    max-width: 30rem;
    transform: translate(0, -14%);
  }

  .hero-img {
    max-width: 55%;
    max-height: 55%;
  }
}
</style>
