<template>
  <section class="section-story" id="story">
    <div class="story">
      <div class="story-content">
        <p class="gradient-text">Наша история</p>
        <h2 class="story-title">Решение инженерных задач</h2>
        <p class="story-paragraph">
          Мы молодая команда преподавателей и инженеров кафедры Геотехники СПбГАСУ, которым в процессе практической и научной деятельности стали интересны смежные специальности – прикладная математика, информационные технологии, а именно: связь инженерной деятельности и программирования.
        </p>
      </div>
      <!-- <img src="../../assets/imgs/story.png" alt="picture" /> -->
      <div class="slider-story">
        <div class="slide-story">
          <img
            class="story-img"
            src="../../assets/imgs/content/террагео_1.png"
            alt="Веб-приложение 'ТерраГео'"
          />
          <div class="story-img__description">Веб-приложение "ТерраГео"</div>
        </div>

        <div class="slide-story">
          <img
            class="story-img"
            src="../../assets/imgs/content/пример_котлована_1.jpg"
            alt="Пример котлована"
          />
          <div class="story-img__description">
            Пример котлована в условиях плотной застройки
          </div>
        </div>

        <div class="slide-story">
          <img
            class="story-img"
            src="../../assets/imgs/content/террагео_2.png"
            alt="Веб-приложение 'ТерраГео'"
          />
          <div class="story-img__description">Веб-приложение "ТерраГео"</div>
        </div>

        <div class="slide-story">
          <img
            class="story-img"
            src="../../assets/imgs/content/пример_объекта_6.jpg"
            alt="Процесс откопки котлована"
          />
          <div class="story-img__description">
            Процесс откопки котлована в условиях городской застройки
          </div>
        </div>

        <div class="slide-story">
          <img
            class="story-img"
            src="../../assets/imgs/content/террагео_2.png"
            alt="Веб-приложение 'ТерраГео'"
          />
          <div class="story-img__description">Веб-приложение "ТерраГео"</div>
        </div>

        <div class="slide-story">
          <img
            class="story-img"
            src="../../assets/imgs/content/пример_объекта_4.jpg"
            alt="Пример модели"
          />
          <div class="story-img__description">
            Пример объекта с усилением опорный зоны моста
          </div>
        </div>

        <div class="slide-story">
          <img
            class="story-img"
            src="../../assets/imgs/content/пример_объекта_7.png"
            alt="Исторический храм"
          />
          <div class="story-img__description">Исторический храм</div>
        </div>

        <div class="slide-story">
          <img
            class="story-img"
            src="../../assets/imgs/content/пример_объекта_8.png"
            alt="Рендер реконструкции храма"
          />
          <div class="story-img__description">
            Рендер реконструкции исторического храма
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      curSlide: 0,
      slides: null,
      maxSlide: 0,
    };
  },
  methods: {
    goToSlide(slide) {
      this.slides.forEach(
        (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
      );
    },
    nextSlide() {
      if (this.curSlide === this.maxSlide - 1) {
        this.curSlide = 0;
      } else {
        this.curSlide++;
      }

      this.goToSlide(this.curSlide);
    },
  },
  mounted() {
    this.slides = document.querySelectorAll('.slide-story');
    this.maxSlide = this.slides.length;

    this.goToSlide(0);

    setInterval(() => {
      this.nextSlide();
    }, 4500);
  },
};
</script>

<style scoped>
.section-story {
  padding-bottom: 20rem;
}

.story {
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gradient-text {
  font-size: 2.6rem;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.story-content {
  max-width: 49.5rem;
  min-width: 47.2rem;
  margin-right: 3rem;
}

.story-title {
  line-height: 1.182em;
  font-size: 4.4rem;
  font-weight: 700;
  /* font-family: 'Thiccc boi bold'; */
  margin-bottom: 2rem;
}

.story-paragraph {
  color: #c9c9c9;
  margin-bottom: 3.4rem;
  line-height: 1.667em;
  font-size: 1.8rem;
}

/* img {
  width: 100%;
  max-width: 61.2rem;
  border-radius: 26px;
} */

/* SLIDER */
.slider-story {
  width: 100%;
  max-width: 61.2rem;
  height: 54rem;
  margin: 0 auto;
  position: relative;

  /* IN THE END */
  overflow: hidden;
}

.slide-story {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.4rem;

  /* THIS creates the animation! */
  transition: transform 1s;
}

.story-img__description {
  color: #c9c9c9;
  font-size: 1.4rem;
}

.story-img {
  max-width: 90%;
  max-height: 90%;
}

@media (max-width: 1250px) {
  .section-story {
    padding-bottom: 4rem;
  }
  .story {
    max-width: 90rem;
  }
  .gradient-text {
    font-size: 2rem;
  }
  .story-title {
    font-size: 3.6rem;
  }
  .story-paragraph {
    font-size: 1.4rem;
  }
  .slider-story {
    height: 48rem;
  }
  .story-img {
    max-width: 90%;
    max-height: 90%;
  }
  .story-img__description {
    font-size: 1.2rem;
  }
}

@media (max-width: 925px) {
  .section-story {
    padding-bottom: 8rem;
  }
  .story {
    max-width: 60rem;
    flex-direction: column;
  }
  .gradient-text {
    font-size: 1.4rem;
  }
  .story-title {
    font-size: 3.6rem;
  }
  .story-paragraph {
    font-size: 1.4rem;
  }
  .story-content {
    margin-right: 0;
  }
  .slider-story {
    height: 25rem;
  }
  .slide-story {
    max-width: 100%;
    max-height: 100%;
  }
  .story-img {
    max-width: 80%;
    max-height: 80%;
  }
}

@media (max-width: 615px) {
  .section-story {
    padding-bottom: 8rem;
  }
  .story {
    max-width: 40rem;
    flex-direction: column;
  }
  .gradient-text {
    font-size: 1.2rem;
  }
  .story-title {
    font-size: 2.1rem;
  }
  .story-paragraph {
    font-size: 1.4rem;
  }
  .story-content {
    margin-right: 0;
    min-width: 40rem;
  }
  .slider-story {
    height: 28rem;
  }
  .slide-story {
    max-width: 100%;
    max-height: 100%;
  }
  .story-img {
    max-width: 80%;
    max-height: 80%;
  }
}

@media (max-width: 415px) {
  .section-story {
    padding-bottom: 8rem;
  }
  .story {
    max-width: 30rem;
    flex-direction: column;
  }
  .gradient-text {
    font-size: 1.2rem;
  }
  .story-title {
    font-size: 1.8rem;
  }
  .story-paragraph {
    font-size: 1.2rem;
  }
  .story-content {
    margin-right: 0;
    min-width: 30rem;
  }
  .slider-story {
    height: 17rem;
  }
  .slide-story {
    max-width: 100%;
    max-height: 100%;
  }
  .story-img {
    max-width: 80%;
    max-height: 80%;
  }
  .story-img__description {
    font-size: 1rem;
  }
}
</style>
