<template>
  <the-navigation></the-navigation>
  <the-hero></the-hero>
  <the-introduce></the-introduce>
  <the-story></the-story>
  <the-about></the-about>
  <the-team></the-team>
  <the-contacts></the-contacts>
</template>

<script>
import TheNavigation from './components/nav/TheNavigation.vue';
import TheHero from './components/hero/TheHero.vue';
import TheIntroduce from './components/introduce/TheIntroduce.vue';
import TheStory from './components/story/TheStory.vue';
import TheAbout from './components/about/TheAbout.vue';
import TheContacts from './components/contacts/TheContacts.vue';
import TheTeam from './components/team/TheTeam.vue';

export default {
  components: {
    TheNavigation,
    TheHero,
    TheIntroduce,
    TheStory,
    TheAbout,
    TheContacts,
    TheTeam,
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 1.4rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
  font-family: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li,
ol li,
li {
  list-style: none;
}
img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

html {
  font-size: 62.5%;
  /* overflow-x: hidden; */
}

/*
- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

/*-------------------------------------------------------*/

:root {
  --bg-color: #13111a;
  --bg-color-d: #000;
  --text-color: #fff;
  --text-color-g: #b7b4c7;
  --text-color-h: #ea5150;
}

@font-face {
  font-family: 'Thiccc boi bold';
  src: url('./assets/fonts/thicccboi/THICCCBOI-Bold.ttf');
}

@font-face {
  font-family: 'Thiccc boi medium';
  src: url('./assets/fonts/thicccboi/THICCCBOI-Medium.ttf');
}

body {
  background-color: var(--bg-color);
  font-family: 'Ubuntu', sans-serif;
  color: var(--text-color);
  overflow-x: hidden;
}

.btn-container {
  min-width: 24rem;
  min-height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-gradient {
  background-image: linear-gradient(to right, #00aad5, #9a85bb 50%, #ea515f);
  transform-style: preserve-3d;
  border-radius: 80px;
  padding: 0.2rem;
  transition: all 0.3s;
  display: inline-block;
  transform: scaleZ(1.01);
  box-shadow: 0 3px 7px rgba(19, 17, 26, 0.14);
}

.btn-gradient > a:link,
.btn-gradient > a:visited {
  background-color: var(--bg-color-d);
  color: var(--text-color);
  transform: scale3d(1, 1, 1.01);
  transform-style: preserve-3d;
  padding: 15px 28px;
  text-align: center;
  letter-spacing: 0.01em;
  border-radius: 1000px;
  font-weight: 700;
  line-height: 1.111em;
  cursor: pointer;
  border: 0;
  display: inline-block;
  font-size: 1.8rem;
}

.btn-gradient:hover {
  transform: scale3d(1.04, 1.04, 1.01);
  transform-style: preserve-3d;
  padding: 0.3rem 0.4rem 0.3rem 0.4rem;
}

.bg-gradient-blur-wrapper {
  width: 100%;
  max-width: 44%;
  min-height: 74%;
  opacity: 0.5;
  filter: blur(100px);
  position: absolute;
  animation: circles backwards 30s infinite;
  animation-timing-function: linear;
  z-index: -100;
  overflow-x: hidden;
}

@keyframes circles {
  0% {
    transform: rotateZ(-360deg);
  }
}

.gradient-text {
  background-image: linear-gradient(270deg, #00aad5, #9a85bb 50%, #ea515f);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

/* SLIDER */
.slider {
  max-width: 100rem;
  height: 54rem;
  margin: 0 auto;
  position: relative;

  /* IN THE END */
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50rem;

  display: flex;
  align-items: center;
  justify-content: center;

  /* THIS creates the animation! */
  transition: transform 1s;
}

/* .slide > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;

  border: none;
  /* background: rgba(255, 255, 255, 0.7); */
  background-color: transparent;
  font-family: inherit;
  color: var(--text-color-g);
  /* border-radius: 50%; */
  height: 5.5rem;
  width: 5.5rem;
  font-size: 3.25rem;
  cursor: pointer;
}

.slider__btn--left {
  left: 15%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 15%;
  transform: translate(50%, -50%);
}

.dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 101;
}

.dots__dot {
  border: none;
  /* background-color: #b9b9b9; */
  opacity: 0.35;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.5s;

  /* Only necessary when overlying images */
  /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  /* background-color: #fff; */
  /* background-color: #888; */
  opacity: 1;
}

/* SLIDER - team */
.slider-team {
  max-width: 100rem;
  height: 54rem;
  margin: 0 auto;
  position: relative;

  /* IN THE END */
  overflow: hidden;
}

.slide-team {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50rem;

  display: flex;
  align-items: center;
  justify-content: center;

  /* THIS creates the animation! */
  transition: transform 1s;
}

/* .slide > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;

  border: none;
  /* background: rgba(255, 255, 255, 0.7); */
  background-color: transparent;
  font-family: inherit;
  color: var(--text-color-g);
  /* border-radius: 50%; */
  height: 5.5rem;
  width: 5.5rem;
  font-size: 3.25rem;
  cursor: pointer;
}

.slider__btn--left {
  left: 15%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 15%;
  transform: translate(50%, -50%);
}

.dots-team {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 101;
}

.dots__dot--team {
  border: none;
  /* background-color: #b9b9b9; */
  opacity: 0.35;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.5s;

  /* Only necessary when overlying images */
  /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
}

.dots__dot--team:last-child {
  margin: 0;
}

.dots__dot--team--active {
  /* background-color: #fff; */
  /* background-color: #888; */
  opacity: 1;
}

@media (max-width: 1440px) {
  .bg-gradient-blur-wrapper {
    display: none;
  }
}

@media (max-width: 925px) {
  .dots__dot--team,
  .dots__dot {
    height: 0.8rem;
    width: 0.8rem;
    transform: translate(0, -800%);
  }
}

@media (max-width: 615px) {
  .dots__dot--team,
  .dots__dot {
    height: 0.8rem;
    width: 0.8rem;
    transform: translate(0, -1000%);
  }
}

@media (max-width: 415px) {
  .dots__dot--team,
  .dots__dot {
    height: 0.8rem;
    width: 0.8rem;
    transform: translate(0, -1400%);
  }
}
</style>
