<template>
  <section class="section-team" id="team">
    <div class="team">
      <div class="gradient-text">Наша команда</div>
    </div>
    <div class="team-info">
      <div class="slider-team">
        <div class="slide-team">
          <img
            class="team-img"
            src="../../assets/imgs/team/Дьяконов_ИП.jpg"
            alt="Дьяконов Иван"
          />
          <h3 class="slide-title">Дьяконов Иван</h3>
          <p class="slide-description">
            Творческий директор. Руководитель научной школы. Разработка сервисов
            для автоматизированного мониторинга.
          </p>
        </div>

        <div class="slide-team">
          <img
            class="team-img"
            src="../../assets/imgs/team/Полунин_ВМ.jpg"
            alt="Полунин Вячеслав"
          />
          <h3 class="slide-title">Полунин Вячеслав</h3>
          <p class="slide-description">
            Руководитель направления. Технический специалист.
            Инженер-программмист. Общее руководство работ.
          </p>
        </div>

        <div class="slide-team">
          <img
            class="team-img"
            src="../../assets/imgs/team/Паскачева_ДА.jpg"
            alt="Паскачева Дарья"
          />
          <h3 class="slide-title">Паскачева Дарья</h3>
          <p class="slide-description">
            Технический специалист. Инженер-программмист. Разработка решателя
            для нелинейных моделей.
          </p>
        </div>

        <div class="slide-team">
          <img
            class="team-img"
            src="../../assets/imgs/team/Кораблев_ДС.jpg"
            alt="Кораблев Денис"
          />
          <h3 class="slide-title">Кораблев Денис</h3>
          <p class="slide-description">
            Технический специалист. Инженер-программмист. Разработка наиболее
            эффективных алогритмов программы, разработка скриптов для
            автоматизации.
          </p>
        </div>

        <div class="slide-team">
          <img
            class="team-img"
            src="../../assets/imgs/team/Башмаков_ИБ.jpg"
            alt="Башмаков Иван"
          />
          <h3 class="slide-title">Башмаков Иван</h3>
          <p class="slide-description">
            Главный технический специалист. Инженер-программмист. Разработка
            решателя для нелинейных моделей.
          </p>
        </div>

        <div class="slide-team">
          <img
            class="team-img"
            src="../../assets/imgs/team/Колюкаев_ИС.jpg"
            alt="Колюкаев Илья"
          />
          <h3 class="slide-title">Колюкаев Илья</h3>
          <p class="slide-description">
            Технический специалист. Инженер-программмист. Разработка решателя
            для нелинейных моделей.
          </p>
        </div>

        <button @click="prevSlide" class="slider__btn slider__btn--left">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
        <button @click="nextSlide" class="slider__btn slider__btn--right">
          <i class="fa-solid fa-arrow-right"></i>
        </button>
        <div class="dots-team"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      curSlide: 0,
      slides: null,
      dotContainer: null,
      maxSlide: 0,
    };
  },
  methods: {
    createDots() {
      const dotContainer = document.querySelector('.dots-team');

      this.slides.forEach(function (_, i) {
        dotContainer.insertAdjacentHTML(
          'beforeend',
          `<button class="dots__dot--team" data-slide-team="${i}"></button>`
        );
      });
    },
    activateDot(slide) {
      document
        .querySelectorAll('.dots__dot--team')
        .forEach((dot) => dot.classList.remove('dots__dot--team--active'));

      document
        .querySelector(`.dots__dot--team[data-slide-team="${slide}"]`)
        .classList.add('dots__dot--team--active');
    },
    goToSlide(slide) {
      this.slides.forEach(
        (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
      );
    },
    nextSlide() {
      if (this.curSlide === this.maxSlide - 1) {
        this.curSlide = 0;
      } else {
        this.curSlide++;
      }

      this.goToSlide(this.curSlide);
      this.activateDot(this.curSlide, this.slides);
    },
    prevSlide() {
      if (this.curSlide === 0) {
        this.curSlide = this.maxSlide - 1;
      } else {
        this.curSlide--;
      }

      this.goToSlide(this.curSlide);
      this.activateDot(this.curSlide);
    },
  },
  mounted() {
    this.slides = document.querySelectorAll('.slide-team');
    this.dotContainer = document.querySelector('.dots-team');
    this.maxSlide = this.slides.length;

    this.goToSlide(0);
    this.createDots();
    this.activateDot(0);

    const that = this;

    this.dotContainer.addEventListener('click', function (e) {
      if (e.target.classList.contains('dots__dot--team')) {
        const slide = e.target.dataset.slideTeam;

        that.goToSlide(slide);
        that.activateDot(slide);
      }
    });
  },
};
</script>

<style scoped>
.section-team {
  padding-bottom: 20rem;
}

.team {
  max-width: 120rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 4.8rem;
}

.gradient-text {
  font-size: 2.6rem;
  letter-spacing: 1px;
  font-weight: 700;
  /* font-family: 'Thiccc boi bold'; */
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.team-info {
  max-width: 125rem;
  margin: 0 auto;
  position: relative;
}

.team-img {
  max-width: 100%;
  max-height: 70%;
  z-index: 100;
  object-fit: fill;
}

.slide-team {
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-description,
.slide-title {
  font-family: inherit;
  font-size: 1.8rem;
  line-height: 1.667em;
  max-width: 60%;
  text-align: center;
}

.slide-title {
  font-size: 2rem;
}

@media (max-width: 1250px) {
  .section-team {
    padding-bottom: 10rem;
  }
  .team {
    max-width: 90rem;
    margin-bottom: 3.6rem;
  }
  .gradient-text {
    font-size: 2rem;
  }
  .team-info {
    max-width: 90rem;
  }
  .team-img {
    max-height: 55%;
  }
  .slide-team {
    gap: 2.4rem;
  }
  .slide-description,
  .slide-title {
    font-size: 1.6rem;
    max-width: 60%;
  }
  .slide-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 925px) {
  .section-team {
    padding-bottom: 4rem;
  }
  .team {
    max-width: 60rem;
    margin-bottom: 0rem;
  }
  .gradient-text {
    font-size: 2rem;
  }
  .team-info {
    max-width: 60rem;
  }
  .slider__btn {
    transform: translate(0, -200%);
  }
  .team-img {
    max-height: 40%;
  }
  .slide-team {
    gap: 1.6rem;
  }
  .slide-description,
  .slide-title {
    font-size: 1.4rem;
    max-width: 50%;
  }
  .slide-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 615px) {
  .section-team {
    padding-bottom: 0rem;
  }
  .team {
    max-width: 40rem;
    margin-bottom: 0rem;
  }
  .gradient-text {
    font-size: 2.1rem;
  }
  .team-info {
    max-width: 40rem;
    transform: translate(0, -8%);
  }
  .slider__btn {
    transform: translate(0, -200%);
  }
  .slider__btn--left {
    transform: translate(-60%, -200%);
  }
  .slider__btn--right {
    transform: translate(60%, -200%);
  }
  .team-img {
    max-height: 35%;
  }
  .slide-team {
    gap: 1.8rem;
  }
  .slide-description,
  .slide-title {
    font-size: 1.2rem;
    max-width: 80%;
  }
  .slide-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 415px) {
  .section-team {
    padding-bottom: 0rem;
  }
  .team {
    max-width: 30rem;
    margin-bottom: 0rem;
  }
  .gradient-text {
    font-size: 1.8rem;
  }
  .team-info {
    max-width: 30rem;
    transform: translate(0, -14%);
  }
  .slider__btn {
    transform: translate(0, -200%);
  }
  .slider__btn--left {
    transform: translate(-60%, -200%);
  }
  .slider__btn--right {
    transform: translate(60%, -200%);
  }
  .team-img {
    max-height: 27%;
  }
  .slide-team {
    gap: 1.8rem;
  }
  .slide-description,
  .slide-title {
    font-size: 1rem;
    max-width: 80%;
  }
  .slide-title {
    font-size: 1.6rem;
  }
}
</style>
