<template>
  <section class="section-introduce" id="introduce">
    <div class="introduce">
      <div class="introduce-header">
        <p class="gradient-text">102lab</p>
        <h2>Занимаемся геотехникой, наукой, МКЭ и автоматизацией</h2>
      </div>
      <div class="introduce-cards">
        <div class="cards-container">
          <div class="card">
            <img src="../../assets/imgs/icons/intro-card1.svg" alt="icon" />
            <div class="card-content">
              <h3 class="card-title">Решение инженерных задач</h3>
              <p class="card-paragraph">
                Мы выполняем задачи, связанные с оценкой влияния нового строительства и реконструкции в условиях плотной городской застройки на существующие здания окружающей застройки; разработкой технических решений устройства котлована для сооружений с развитым подземным пространством; вариантным проектированием фундаментов зданий и сооружений.
              </p>
            </div>
          </div>
          <div class="card">
            <img src="../../assets/imgs/icons/intro-card2.svg" alt="icon" />
            <div class="card-content">
              <h3 class="card-title short">Решение научных задач</h3>
              <p class="card-paragraph">
                Наша деятельность связана с численным моделированием, поэтому нам важно понимать все математические процессы на более глубоком, «низком», уровне. На данный момент мы разрабатываем свой программный комплекс для решения прикладных задач геотехники с использованием метода конечных элементов.
              </p>
            </div>
          </div>
          <div class="card">
            <img src="../../assets/imgs/icons/intro-card3.svg" alt="icon" />
            <div class="card-content">
              <h3 class="card-title">Автоматизация и мониторинг</h3>
              <p class="card-paragraph">
                Мы часто сталкиваемся с острой необходимостью автоматизации таких рутинных процессов, как обработка и автоматический анализ результатов расчета, визуализация и аналитика результатов натурных наблюдений. В существующих коммерческих программных комплексах нет таких инструментов.
              </p>
            </div>
          </div>
        </div>
        <div class="bg-gradient-blur-wrapper">
          <div class="bg-gradient-blur-circle-1"></div>
          <div class="bg-gradient-blur-circle-2"></div>
          <div class="bg-gradient-blur-circle-3"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.section-introduce {
  padding: 18rem 0;
}

.introduce-header {
  max-width: 81rem;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 4.8rem;
}

.gradient-text {
  font-size: 2.6rem;
  letter-spacing: 1px;
  font-weight: 700;
  /* font-family: 'Thiccc boi bold'; */
  margin-bottom: 2rem;
}

h2 {
  font-size: 4.4rem;
  font-weight: 700;
  /* font-family: 'Thiccc boi bold'; */
  line-height: 1.182em;
}

.introduce-cards {
  max-width: 120rem;
  margin: 0 auto;
  position: relative;
}

.cards-container {
  z-index: 1;
  display: grid;
  grid-column-gap: 2.5rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.card {
  padding: 4rem 3.2rem 5.6rem;
  background-color: #1d1a27;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  overflow: hidden;
}

img {
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 16px;
  margin-bottom: 2.2rem;
}

.card-title {
  font-size: 2.4rem;
  font-weight: 700;
  /* font-family: 'Thiccc boi bold'; */
  line-height: 1.5em;
  margin-bottom: 0.9rem;
}

.card-paragraph {
  color: #c9c9c9;
  line-height: 1.667em;
  font-size: 1.8rem;
}

.bg-gradient-blur-wrapper {
  max-width: 35%;
  min-height: 90%;
  top: 0;
  left: 32%;
}

.bg-gradient-blur-circle-1 {
  width: 87%;
  height: 87%;
  background-color: #00aad5;
  position: absolute;
  top: 0;
  right: 0;
}

.bg-gradient-blur-circle-2 {
  width: 70%;
  height: 70%;
  background-color: #9a85bb;
  position: absolute;
  bottom: 0;
  right: 0;
}

.bg-gradient-blur-circle-3 {
  width: 87%;
  height: 87%;
  background-color: #ea515f;
  position: absolute;
  bottom: 0;
}

.short {
  max-width: 25rem;
}

@media (max-width: 1250px) {
  .section-introduce {
    padding: 10rem 0;
    padding-bottom: 4rem;
  }
  .introduce-header {
    max-width: 60rem;
    padding-bottom: 3.6rem;
  }
  .gradient-text {
    font-size: 2rem;
    letter-spacing: 1px;
    font-weight: 700;
    /* font-family: 'Thiccc boi bold'; */
    margin-bottom: 2rem;
  }
  h2 {
    font-size: 3.6rem;
    font-weight: 700;
    /* font-family: 'Thiccc boi bold'; */
    line-height: 1.182em;
  }
  .introduce-cards {
    max-width: 90rem;
  }
  img {
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 1.8rem;
  }
  .card-title {
    font-size: 1.8rem;
    margin-bottom: 0.9rem;
  }
  .card-paragraph {
    color: #c9c9c9;
    line-height: 1.667em;
    font-size: 1.4rem;
  }
}

@media (max-width: 925px) {
  .section-introduce {
    padding: 8rem 0;
    padding-bottom: 8rem;
  }
  .introduce-header {
    max-width: 40rem;
    padding-bottom: 3.6rem;
  }
  .gradient-text {
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-weight: 700;
    /* font-family: 'Thiccc boi bold'; */
    margin-bottom: 2rem;
  }
  h2 {
    font-size: 2.4rem;
    font-weight: 700;
    /* font-family: 'Thiccc boi bold'; */
    line-height: 1.182em;
  }
  .introduce-cards {
    max-width: 30rem;
  }
  img {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1.4rem;
  }
  .card {
    padding: 3rem 2.4rem 4rem;
    background-color: #1d1a27;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    overflow: hidden;
  }
  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  .card-title {
    font-size: 1.6rem;
    margin-bottom: 0.9rem;
  }
  .card-paragraph {
    color: #c9c9c9;
    line-height: 1.667em;
    font-size: 1.3rem;
  }
}

@media (max-width: 615px) {
  .section-introduce {
    padding: 6rem 0;
    padding-bottom: 8rem;
  }
  .introduce-header {
    max-width: 40rem;
    padding-bottom: 3.6rem;
  }
  .gradient-text {
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  h2 {
    font-size: 2.1rem;
    font-weight: 700;
  }
  .introduce-cards {
    max-width: 30rem;
  }
  img {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1.4rem;
  }
  .card {
    padding: 3rem 2.4rem 4rem;
    background-color: #1d1a27;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    overflow: hidden;
  }
  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  .card-title {
    font-size: 1.6rem;
    margin-bottom: 0.9rem;
  }
  .card-paragraph {
    color: #c9c9c9;
    line-height: 1.667em;
    font-size: 1.3rem;
  }
}

@media (max-width: 415px) {
  .section-introduce {
    padding: 0;
    padding-bottom: 8rem;
  }
  .introduce-header {
    max-width: 30rem;
    padding-bottom: 3.6rem;
  }
  .gradient-text {
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
  }
  .introduce-cards {
    max-width: 24rem;
  }
  img {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1.4rem;
  }
  .card {
    padding: 3rem 2.4rem 4rem;
    background-color: #1d1a27;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
    border-radius: 22px;
    overflow: hidden;
  }
  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  .card-title {
    font-size: 1.4rem;
    margin-bottom: 0.9rem;
  }
  .card-paragraph {
    color: #c9c9c9;
    line-height: 1.667em;
    font-size: 1rem;
  }
}
</style>
