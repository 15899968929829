<template>
  <header>
    <nav>
      <a href="/">
        <div class="logo">
          <span>102</span>
          <div class="logo-circle">lab</div>
        </div>
      </a>
      <ul class="nav-list">
        <li class="nav-item">
          <a class="nav__link" href="#story">История</a>
        </li>
        <li class="nav-item">
          <a class="nav__link" href="#about">Примеры работ</a>
        </li>
        <li class="nav-item">
          <a class="nav__link" href="#contacts">Контакты</a>
        </li>
      </ul>
    </nav>
  </header>
  <div class="fund__container">
    <div class="fund__logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Слой_1"
        x="0px"
        y="0px"
        width="200.4px"
        height="97.9px"
        viewBox="0 0 200.4 97.9"
        style="enable-background: new 0 0 200.4 97.9"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              class="st0"
              d="M12.1,15.9c0,0.8-0.1,1.4-0.4,2c-0.3,0.5-0.6,1-1.1,1.4c-0.4,0.4-0.9,0.7-1.5,0.9c-0.5,0.2-1.1,0.3-1.7,0.4    V22H4.7v-1.4h0c-0.8-0.1-1.6-0.3-2.2-0.6S1.4,19.3,1,18.9c-0.4-0.4-0.6-1-0.8-1.6S0,16.1,0,15.4V9.6c0-0.7,0.1-1.4,0.4-2    C0.7,7,1,6.6,1.5,6.2C1.9,5.8,2.4,5.5,3,5.3C3.5,5.1,4.1,5,4.7,5V4.1h2.8l0,0.9c0.6,0,1.1,0.2,1.7,0.4c0.5,0.2,1,0.5,1.5,0.9    c0.4,0.4,0.8,0.9,1.1,1.4c0.3,0.6,0.4,1.2,0.4,2V15.9z M4.7,7.2C4,7.3,3.6,7.5,3.2,7.9C2.8,8.2,2.6,8.7,2.6,9.4v6.8    c0,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.5,0.5,0.6c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.1,0.7,0.2V7.2z M9.5,9.4    c0-0.7-0.2-1.2-0.6-1.5C8.5,7.5,8.1,7.3,7.5,7.2l0,11c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.2,0.7-0.4C9,17.5,9.2,17.3,9.3,17    c0.1-0.2,0.2-0.5,0.2-0.8V9.4z"
            />
            <path
              class="st0"
              d="M25.9,17.4c0,1.6-0.4,2.7-1.1,3.5c-0.7,0.8-1.8,1.2-3.3,1.2c-1.5,0-2.6-0.4-3.3-1.1c-0.7-0.8-1-1.9-1-3.3v-9    c0-0.7,0.1-1.3,0.2-1.9c0.2-0.6,0.4-1.1,0.8-1.5c0.3-0.4,0.8-0.7,1.4-1c0.6-0.2,1.2-0.3,2-0.3c0.8,0,1.5,0.1,2.1,0.3    c0.6,0.2,1,0.5,1.3,0.9c0.3,0.4,0.6,0.9,0.7,1.5c0.1,0.6,0.2,1.2,0.2,2V17.4z M23.1,8.4c0-0.5-0.1-0.9-0.3-1.3    c-0.2-0.4-0.6-0.6-1.2-0.6c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5C20.1,7.5,20.1,7.7,20,8c0,0.3-0.1,0.5-0.1,0.7v9    c0,0.5,0.1,0.9,0.4,1.2c0.2,0.4,0.6,0.6,1.2,0.6c0.6,0,1-0.2,1.2-0.6c0.2-0.4,0.4-0.9,0.4-1.4V8.4z"
            />
            <path
              class="st0"
              d="M37.2,22v-7.8H34V22h-2.8V4.1H34v7.5h3.2V4.1H40V22H37.2z"
            />
            <path
              class="st0"
              d="M46.6,22v3.4h-2.4v-5.7l1.1-0.2c0.5-0.5,0.8-1.1,1-1.8c0.2-0.7,0.3-1.6,0.3-2.7l0.3-10.9H55v15.4h1.1v5.9    h-2.4V22H46.6z M49.1,14.9c0,1-0.1,1.9-0.2,2.7c-0.1,0.7-0.3,1.4-0.6,1.9h3.9V6.7h-2.9L49.1,14.9z"
            />
            <path
              class="st0"
              d="M73,3.9c0.7,0,1.4,0.1,1.9,0.3c0.5,0.2,1,0.5,1.3,0.9c0.4,0.4,0.6,0.8,0.8,1.3c0.2,0.5,0.2,1.1,0.2,1.7    l-2.7,0.7c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.6-1.2-0.6c-0.3,0-0.6,0.1-0.8,0.2C72,6.9,71.8,7,71.7,7.2    c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.2-0.1,0.5-0.1,0.7v9.1c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.3,0.5,0.5    c0.2,0.1,0.4,0.2,0.8,0.2c1,0,1.6-0.7,1.6-2.2c0.5,0.1,0.9,0.2,1.4,0.2c0.5,0.1,0.9,0.1,1.4,0.2c0,1.4-0.4,2.4-1.1,3.2    c-0.7,0.8-1.8,1.2-3.2,1.2c-1.5,0-2.6-0.4-3.3-1.1c-0.7-0.7-1.1-1.8-1.1-3.3V8.8c0-0.7,0.1-1.3,0.2-1.9c0.1-0.6,0.4-1.1,0.7-1.6    c0.3-0.4,0.8-0.8,1.4-1.1C71.5,4,72.2,3.9,73,3.9z"
            />
            <path
              class="st0"
              d="M90.6,17.4c0,1.6-0.4,2.7-1.1,3.5c-0.7,0.8-1.8,1.2-3.3,1.2c-1.5,0-2.6-0.4-3.3-1.1c-0.7-0.8-1-1.9-1-3.3v-9    c0-0.7,0.1-1.3,0.2-1.9c0.2-0.6,0.4-1.1,0.8-1.5c0.3-0.4,0.8-0.7,1.4-1c0.6-0.2,1.2-0.3,2-0.3c0.8,0,1.5,0.1,2.1,0.3    c0.6,0.2,1,0.5,1.3,0.9c0.3,0.4,0.6,0.9,0.7,1.5c0.1,0.6,0.2,1.2,0.2,2V17.4z M87.8,8.4c0-0.5-0.1-0.9-0.3-1.3    c-0.2-0.4-0.6-0.6-1.2-0.6c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.3-0.1,0.5-0.1,0.7v9    c0,0.5,0.1,0.9,0.4,1.2c0.2,0.4,0.6,0.6,1.2,0.6c0.6,0,1-0.2,1.2-0.6c0.2-0.4,0.4-0.9,0.4-1.4V8.4z"
            />
            <path
              class="st0"
              d="M97.1,22v3.4h-2.4v-5.7l1.1-0.2c0.5-0.5,0.8-1.1,1-1.8c0.2-0.7,0.3-1.6,0.3-2.7l0.3-10.9h8.1v15.4h1.1v5.9    h-2.4V22H97.1z M99.6,14.9c0,1-0.1,1.9-0.2,2.7c-0.1,0.7-0.3,1.4-0.6,1.9h3.9V6.7h-2.9L99.6,14.9z"
            />
            <path
              class="st0"
              d="M111.2,22V4.1h8v2.6H114v4.9h4.5v2.6H114v5.2h5.2V22H111.2z"
            />
            <path
              class="st0"
              d="M130.1,10.7L126.4,22h-2.7V4.1h2.7v11.3h0.1l3.8-11.3h2.7V22h-2.7L130.1,10.7L130.1,10.7z M126.8,0    c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.5-0.1,0.7-0.2    c0.2-0.1,0.4-0.3,0.5-0.4c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.1-0.6h2.1c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.4-0.4,0.8-0.7,1.2    c-0.3,0.4-0.7,0.6-1.1,0.9c-0.4,0.2-1,0.3-1.6,0.3c-0.7,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.8-0.5-1.1-0.9c-0.3-0.4-0.5-0.7-0.6-1.2    c-0.1-0.4-0.2-0.8-0.2-1.2H126.8z"
            />
            <path
              class="st0"
              d="M142.5,3.9c0.7,0,1.4,0.1,1.9,0.3c0.5,0.2,1,0.5,1.3,0.9c0.4,0.4,0.6,0.8,0.8,1.3c0.2,0.5,0.2,1.1,0.2,1.7    l-2.7,0.7c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.6-1.2-0.6c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5    c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.2-0.1,0.5-0.1,0.7v9.1c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.3,0.5,0.5    c0.2,0.1,0.5,0.2,0.8,0.2c1,0,1.6-0.7,1.6-2.2c0.5,0.1,0.9,0.2,1.4,0.2c0.5,0.1,0.9,0.1,1.4,0.2c0,1.4-0.4,2.4-1.1,3.2    c-0.7,0.8-1.8,1.2-3.2,1.2c-1.5,0-2.6-0.4-3.3-1.1c-0.7-0.7-1.1-1.8-1.1-3.3V8.8c0-0.7,0.1-1.3,0.2-1.9c0.1-0.6,0.4-1.1,0.7-1.6    c0.3-0.4,0.8-0.8,1.4-1.1C141,4,141.7,3.9,142.5,3.9z"
            />
            <path class="st0" d="M156,6.7V22h-2.8V6.7h-2.9V4.1h8.6v2.6H156z" />
            <path
              class="st0"
              d="M169.7,12.6c0.8,0.3,1.3,0.7,1.6,1.5c0.3,0.7,0.4,1.5,0.4,2.5c0,1.9-0.4,3.2-1.2,4.1c-0.8,0.9-2,1.3-3.7,1.3    h-3.7V4.1h3.9c1.6,0,2.7,0.4,3.5,1.2c0.7,0.8,1.1,2,1.1,3.6c0,0.8-0.1,1.5-0.4,2.2c-0.3,0.6-0.8,1.1-1.5,1.4V12.6z M168.9,9    c0-0.8-0.2-1.4-0.5-1.8c-0.3-0.4-0.9-0.5-1.6-0.5h-0.9v4.6h0.9c0.7,0,1.3-0.2,1.6-0.6C168.7,10.3,168.9,9.7,168.9,9z M169.1,16.3    c0-0.8-0.2-1.5-0.5-1.9c-0.3-0.4-0.9-0.6-1.6-0.6h-1.1v5.6h1.1c0.4,0,0.8-0.1,1.1-0.2c0.3-0.2,0.5-0.4,0.6-0.7    c0.1-0.3,0.2-0.6,0.3-1C169,17.1,169.1,16.7,169.1,16.3z"
            />
            <path
              class="st0"
              d="M183.4,10.7L179.6,22h-2.7V4.1h2.7v11.3h0.1l3.8-11.3h2.7V22h-2.7L183.4,10.7L183.4,10.7z"
            />
            <path
              class="st0"
              d="M190.8,22l1-5.7c0.1-0.6,0.3-1.1,0.6-1.6c0.3-0.5,0.6-0.9,1.1-1.1c-0.8-0.3-1.4-0.9-1.7-1.6    c-0.3-0.7-0.5-1.5-0.5-2.5c0-1.8,0.4-3.1,1.3-4c0.9-0.9,2-1.4,3.5-1.4h4.1V22h-2.8v-7.6h-0.7c-0.5,0-0.9,0.2-1.3,0.6    c-0.4,0.4-0.7,1-0.9,2l-0.9,5.1H190.8z M196.4,6.7c-0.8,0-1.4,0.3-1.8,0.8c-0.3,0.5-0.5,1.2-0.5,2c0,0.9,0.2,1.5,0.6,1.8    c0.4,0.3,1,0.5,1.6,0.5h1.1V6.7H196.4z"
            />
            <path
              class="st0"
              d="M8.4,36.9L3.7,50.8H0.4V28.6h3.4v14.1h0.1l4.7-14.1h3.4v22.2H8.5L8.4,36.9L8.4,36.9z"
            />
            <path
              class="st0"
              d="M28.9,50.8v-9.7h-4v9.7h-3.5V28.6h3.5v9.3h4v-9.3h3.5v22.2H28.9z"
            />
            <path
              class="st0"
              d="M49.5,50.8v-9.7h-4v9.7H42V28.6h3.5v9.3h4v-9.3H53v22.2H49.5z"
            />
            <path
              class="st0"
              d="M73.2,45.1c0,1.9-0.4,3.4-1.3,4.4c-0.9,1-2.3,1.5-4.1,1.5c-1.9,0-3.2-0.5-4.1-1.4c-0.9-0.9-1.3-2.3-1.3-4.1    V34.3c0-0.9,0.1-1.7,0.3-2.4c0.2-0.7,0.5-1.3,0.9-1.9c0.4-0.5,1-0.9,1.7-1.2c0.7-0.3,1.5-0.4,2.5-0.4c1,0,1.9,0.1,2.6,0.4    c0.7,0.3,1.3,0.7,1.7,1.2c0.4,0.5,0.7,1.1,0.9,1.9c0.2,0.7,0.3,1.5,0.3,2.5V45.1z M69.8,34c0-0.6-0.1-1.2-0.4-1.7    c-0.3-0.5-0.8-0.7-1.5-0.7c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.1,0.6-0.1,0.9    v11.2c0,0.6,0.1,1.1,0.4,1.5c0.3,0.5,0.8,0.7,1.5,0.7c0.7,0,1.2-0.2,1.5-0.7c0.3-0.5,0.5-1.1,0.5-1.7V34z"
            />
            <path
              class="st0"
              d="M90.9,39.2c1,0.3,1.7,0.9,2,1.8c0.4,0.9,0.5,1.9,0.5,3.1c0,2.3-0.5,4-1.5,5.1c-1,1.1-2.5,1.6-4.6,1.6h-4.6    V28.6h4.9c2,0,3.4,0.5,4.3,1.5c0.9,1,1.3,2.5,1.3,4.5c0,1-0.2,1.9-0.5,2.7c-0.4,0.8-1,1.3-1.8,1.7V39.2z M89.9,34.7    c0-1-0.2-1.8-0.6-2.2c-0.4-0.4-1.1-0.7-2-0.7h-1.1v5.7h1.1c0.9,0,1.6-0.2,2-0.7C89.7,36.3,89.9,35.6,89.9,34.7z M90.1,43.7    c0-1-0.2-1.8-0.6-2.3c-0.4-0.5-1.1-0.8-2-0.8h-1.3v6.9h1.4c0.6,0,1-0.1,1.3-0.3c0.3-0.2,0.6-0.5,0.8-0.8c0.2-0.3,0.3-0.7,0.4-1.2    C90.1,44.7,90.1,44.3,90.1,43.7z"
            />
            <path
              class="st0"
              d="M110.9,50.8l-0.9-4.6h-4.1c-0.2,0.8-0.3,1.5-0.4,2.3c-0.1,0.8-0.3,1.5-0.4,2.3h-3.5c0.8-3.7,1.5-7.4,2.3-11.1    c0.8-3.7,1.5-7.4,2.3-11.1h3.9l4.5,22.2H110.9z M107.9,33.7c-0.2,1.5-0.4,3.1-0.7,4.6c-0.2,1.5-0.5,3.1-0.8,4.6h3.1l-1.4-9.2    H107.9z"
            />
            <path
              class="st0"
              d="M133.5,28.6v19h1.4V55h-2.9v-4.2h-9.4V28.6h3.5v19h3.9v-19H133.5z"
            />
            <path
              class="st0"
              d="M151.3,36.9l-4.7,13.9h-3.3V28.6h3.4v14.1h0.1l4.7-14.1h3.4v22.2h-3.4L151.3,36.9L151.3,36.9z"
            />
            <path
              class="st0"
              d="M163.5,50.8l1.3-7.1c0.2-0.7,0.4-1.4,0.7-2c0.3-0.6,0.8-1.1,1.4-1.4c-1-0.4-1.7-1.1-2.1-1.9    c-0.4-0.9-0.6-1.9-0.6-3.1c0-2.2,0.5-3.8,1.6-5c1.1-1.1,2.5-1.7,4.4-1.7h5.1v22.2h-3.5v-9.5h-0.9c-0.6,0-1.2,0.2-1.7,0.7    c-0.5,0.5-0.9,1.3-1.1,2.5l-1.1,6.3H163.5z M170.3,31.8c-1.1,0-1.8,0.3-2.2,0.9c-0.4,0.6-0.6,1.4-0.6,2.5c0,1.1,0.3,1.8,0.8,2.2    c0.5,0.4,1.2,0.6,2,0.6h1.4v-6.3H170.3z"
            />
            <path
              class="st0"
              d="M191.2,45.9l-1.6-4.1l-1.4-3.9h-0.2v12.9h-3.5V28.6h3.4l2.4,6.3l1.8,4.8h0.2l1.8-4.9l2.4-6.2h3.3v22.2h-3.5    V38h-0.1l-1.4,4l-1.6,4H191.2z"
            />
          </g>
          <g>
            <g>
              <path
                class="st1"
                d="M200.1,57.5C200.1,57.5,200.1,57.5,200.1,57.5c-48.2,47.2-144.7,39-179.7,0H0.5c26.4,28,64.3,38.6,96.1,40     c3.3,0.1,6.5,0.2,9.7,0.1c20.6-0.3,43.6-4.8,63.5-15.4C181.2,76.1,191.6,68,200.1,57.5z"
              />
              <path
                class="st2"
                d="M200.1,57.5C200.1,57.5,200.1,57.5,200.1,57.5c-0.8,0.6-1.5,1-2.2,1.5C145.4,94.1,72.2,87.2,40.9,57.5H20.4     C55.4,96.5,151.9,104.7,200.1,57.5z"
              />
              <path
                class="st0"
                d="M188.5,62.4c-23.9,9.3-46.4,13.3-66,13.4C95.1,76,73.4,68.6,61.8,57.5H44.4h-3.6C72,87,144.3,94,196.8,59.7     c0.4-0.2,0.7-0.5,1.1-0.7c0.7-0.5,1.4-1,2.1-1.4c0,0,0,0,0.1,0C196.2,59.3,192.4,60.9,188.5,62.4z"
              />
              <path
                class="st3"
                d="M61.8,57.5c11.4,11,32.6,18.3,59.6,18.3c0.4,0,0.7,0,1.1,0c19.6-0.1,42.2-4.2,66-13.4     c3.8-1.5,7.7-3.1,11.6-4.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-8.5,10.5-18.9,18.6-30.4,24.7     c-19.9,10.6-42.9,15.1-63.5,15.4c-0.8,0-1.6,0-2.4,0c-2.4,0-4.8-0.1-7.3-0.2c-31.8-1.4-69.6-12-96.1-40h19.9h20.5h3.6H61.8      M200.1,57.5L200.1,57.5 M61.8,57.2H44.4h-3.6H20.4H0.5c-0.1,0-0.2,0.1-0.3,0.2c0,0.1,0,0.2,0,0.3c12.3,13,27.8,23.1,46.1,30     c15.4,5.8,32.7,9.3,50.1,10c2.4,0.1,4.9,0.2,7.3,0.2c0.8,0,1.6,0,2.4,0c10.9-0.2,21.7-1.5,32.1-3.8c11.4-2.6,22-6.5,31.6-11.6     c12.2-6.5,22.4-14.8,30.5-24.8h0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0c0,0,0-0.1,0-0.1l0,0v0c0,0,0-0.1,0-0.1l0,0l0,0     c0,0,0-0.1,0-0.1l0,0l0,0c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0l0,0l0,0c0,0,0,0-0.1,0h0c0,0-0.1,0-0.1,0c-3.8,1.7-7.7,3.4-11.5,4.9     c-22.2,8.6-45,13.3-65.9,13.4c-0.4,0-0.8,0-1.1,0C95.7,75.5,74,68.9,62,57.3C62,57.3,61.9,57.2,61.8,57.2L61.8,57.2z"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="fund__text">
      Работа выполнена при поддержке гранта Фонда содействия инновациям,
      предоставленного в рамках программы «Студенческий стартап» федерального
      проекта «Платформа университетского технологического предпринимательства»
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.querySelector('.nav-list').addEventListener('click', function (e) {
      e.preventDefault();

      if (e.target.classList.contains('nav__link')) {
        const id = e.target.getAttribute('href');
        document.querySelector(id).scrollIntoView({ behavior: 'smooth' });
      }
    });
  },
};
</script>

<style scoped>
header {
  padding: 2.6rem 0;
}

nav {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  font-size: 1.8rem;
  min-height: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 2.2rem;
  cursor: pointer;
  min-width: 12rem;
}

.logo > span {
  transition: all 0.3s;
  display: block;
}

.logo:hover {
  font-size: 2.4rem;

  .logo-circle {
    min-width: 6.4rem;
    min-height: 6.4rem;
  }
}

.logo-circle {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 6rem;
  min-height: 6rem;
  border: 2px solid transparent;
  border-radius: 80px;
  background-image: linear-gradient(var(--bg-color), var(--bg-color)),
    linear-gradient(45deg, #00aad5, #9a85bb 50%, #ea515f);
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition: all 0.3s;
}

.nav-list {
  display: flex;
  align-items: center;
}

.nav-item {
  margin-right: 3.2rem;
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-item > a:link,
.nav-item > a:visited {
  color: var(--text-color);
  transition: color 0.3s;
}

.nav-item > a:hover,
.nav-item > a:active {
  color: var(--text-color-h);
}

a {
  color: var(--text-color);
}

.fund__container {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2.4rem;
}

.fund__text {
  max-width: 55rem;
  line-height: 1.6;
  color: #c9c9c9;
  text-align: center;
}

.st0 {
  fill: #ffffff;
}

.st1 {
  fill: #ea2227;
}

.st2 {
  fill: #25a8e0;
}

.st3 {
  fill: #cdcccb;
}

@media (max-width: 1440px) {
  nav {
    max-width: 90rem;
  }
}

@media (max-width: 925px) {
  nav {
    max-width: 60rem;
    font-size: 1.4rem;
  }

  .logo {
    font-size: 1.4rem;
  }

  .logo:hover {
    font-size: 1.4rem;

    .logo-circle {
      min-width: 4rem;
      min-height: 4rem;
    }
  }
  .logo-circle {
    min-width: 4rem;
    min-height: 4rem;
    border: 2px solid transparent;
  }
  .fund__container {
    max-width: 60rem;
    gap: 2rem;
  }
  .fund__logo {
    text-align: center;
  }
  svg {
    max-width: 80%;
  }
  .fund__text {
    max-width: 40rem;
    font-size: 1rem;
  }
}

@media (max-width: 615px) {
  nav {
    max-width: 40rem;
    font-size: 1.2rem;
  }

  .logo {
    font-size: 1.4rem;
  }

  .logo:hover {
    font-size: 1.4rem;

    .logo-circle {
      min-width: 4rem;
      min-height: 4rem;
    }
  }
  .logo-circle {
    min-width: 4rem;
    min-height: 4rem;
    border: 2px solid transparent;
  }
  .fund__container {
    max-width: 55rem;
    gap: 1.8rem;
  }
  svg {
    max-width: 70%;
  }
  .fund__text {
    max-width: 34rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 415px) {
  nav {
    max-width: 30rem;
    font-size: 1rem;
    gap: 1.3rem;
  }

  .nav-item {
    margin-right: 2rem;
  }

  .nav-item:last-child {
    margin-right: 0;
  }

  .logo {
    font-size: 1.4rem;
    min-width: 0;
  }

  .logo:hover {
    font-size: 1.4rem;

    .logo-circle {
      min-width: 4rem;
      min-height: 4rem;
    }
  }
  .logo-circle {
    min-width: 4rem;
    min-height: 4rem;
    border: 2px solid transparent;
  }
  .fund__container {
    max-width: 45rem;
    gap: 1.4rem;
  }
  svg {
    max-width: 60%;
  }
  .fund__text {
    max-width: 30rem;
    font-size: 0.8rem;
    margin-bottom: 1.4rem;
  }
}
</style>
